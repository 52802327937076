import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap"
import Gallery from "react-grid-gallery"
import ReactPlayer from "react-player"
import Img from "gatsby-image"
import { SocialIcon } from "react-social-icons"
import { MDXRenderer } from "gatsby-plugin-mdx"

class ArtistPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lang: 'fr' };
    this.handleLangClick = this.handleLangClick.bind(this);
  }

  handleLangClick() {
    this.setState(prevState => ({
      lang: prevState.lang === 'fr' ? 'en' : 'fr',
    }));
  }

  render() {
    const page = this.props.data.mdx
    const { previous, next } = this.props.pageContext
    const {
      title,
      country,
      color,
      hideTitle,
      hasHeader,
      albums,
      photos,
      videos,
      presse,
      social,
    } = page.frontmatter

    return (
      <Layout location={this.props.location}>
        <SEO
          title={title}
          description={page.frontmatter.description || page.excerpt}
          image={page.frontmatter.cover.publicURL}
        />
        <style>
          {`
          .fr-content, .en-content {
            display: none;
          }
          .fr .fr-content, .en .en-content {
            display: block;
          }
          `}
        </style>
        <Row className="justify-content-center mb-4">
          <Col xl={9} md={10}>
            {!hideTitle && <h1>{title}</h1>}
            {albums && (
              <AlbumsPanel className="mb-3">
                {albums.map(({ title, cover }, id) => (
                  <Col key={id} sm={5} xs={6}>
                    <AlbumCard className="my-2">
                      {cover && (
                        <Img fluid={cover.childImageSharp.fluid} alt={title} />
                      )}
                      <Card.Body>
                        <Card.Title className="mb-0">{title}</Card.Title>
                      </Card.Body>
                    </AlbumCard>
                  </Col>
                ))}
              </AlbumsPanel>
            )}
            <Card className="mt-4">
              <Card.Body className={`p-4 ${this.state.lang}`}>
                {country && (
                  <TopLeftTag strokeColor={color}>{country}</TopLeftTag>
                )}
                <Content>
                  {hasHeader && <H3 size="medium">En quelques mots...</H3>}
                </Content>
                <Content strokeColor={color}>
                  {page.body.includes('en-content') ? (
                    <Button size="sm" className="float-right align-self-end my-2" onClick={this.handleLangClick}>
                      {this.state.lang === 'fr' ? '🇺🇸 English version' : '🇫🇷 Version française'}
                    </Button>) : ''}
                  <MDXRenderer>{page.body}</MDXRenderer>
                </Content>
              </Card.Body>
            </Card>
            {photos && (
              <Card className="mt-4">
                <Card.Body className="p-4">
                  <Content>
                    <H3>Photos</H3>
                    <Gallery
                      images={photos.map(p => ({
                        src: p.src.publicURL,
                        caption: p.title,
                        alt: p.alt,
                        thumbnail: p.src.thumbnail.fluid.src,
                        thumbnailWidth: p.src.thumbnail.fluid.presentationWidth,
                        thumbnailHeight:
                          p.src.thumbnail.fluid.presentationHeight,
                      }))}
                      imageCountSeparator=" / "
                      enableImageSelection={false}
                      backdropClosesModal
                    />
                  </Content>
                </Card.Body>
              </Card>
            )}
            {videos && (
              <Card className="mt-4">
                <Card.Body className="p-4">
                  <Content>
                    <H3>Vidéos</H3>
                    {videos
                      .map(v => (
                        <Col sm={4} className="my-1" key={v.url}>
                          <ReactPlayer
                            url={v.url || v.file.publicURL}
                            wrapper={({ style, ...props }) => (
                              <PlayerWrapper {...props} />
                            )}
                            playing
                            controls
                            light
                          />
                          <H5>{v.title}</H5>
                        </Col>
                      ))
                      .reduce((r, v, i) => {
                        if (i % 3 === 0) r.push([])
                        r[r.length - 1].push(v)
                        return r
                      }, [])
                      .map((r, i) => (
                        <Row key={i}>{r}</Row>
                      ))}
                  </Content>
                </Card.Body>
              </Card>
            )}
            {presse && (
              <Card className="mt-4">
                <Card.Body className="p-4">
                  <Content>
                    <H3>Médias</H3>
                    <Row>
                      {presse.map(p => (
                        <Col key={p.url} lg={6} className="mb-2">
                          <Row
                            as="a"
                            href={p.url}
                            className="h-100 align-items-center"
                          >
                            {p.image && (
                              <Img
                                fixed={p.image.childImageSharp.fixed}
                                className="mr-2"
                              />
                            )}
                            <span>{p.title}</span>
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </Content>
                </Card.Body>
              </Card>
            )}
            {social && (
              <Card className="mt-4">
                <Card.Body className="p-4">
                  <Content>
                    <H3>Social</H3>
                    <Row className="mx-auto">
                      {social.map(s => (
                        <Col key={s}>
                          <SocialIcon url={s} />
                        </Col>
                      ))}
                    </Row>
                  </Content>
                </Card.Body>
              </Card>
            )}
            <hr />
            <ButtonGroup>
              {next && (
                <Button as={Link} to={next.fields.slug} rel="next">
                  ← {next.frontmatter.title}
                </Button>
              )}
              {previous && (
                <Button as={Link} to={previous.fields.slug} rel="prev">
                  {previous.frontmatter.title} →
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </Layout>
    )
  }
}

const AlbumsPanel = styled(Row)`
  @media only screen and (min-width: 992px) {
    flex-wrap: nowrap;
    overflow: scroll;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-radius: 5px;
  }
`

const AlbumCard = styled(Card)``

const TopLeftTag = styled.h4`
  color: white;
  text-transform: uppercase;
  padding: 4px 8px;
  position: absolute;
  top: -10px;
  right: -20px;
  transform: rotate(10deg);

  ${p => p.theme.main.mixins.highlighted(p)};
`

const H3 = styled.h3`
  align-self: center;
  position: relative;
  top: -30px;
  z-index: 1;
  color: white;
  text-transform: uppercase;

  ${p => p.theme.main.mixins.highlighted(p)}
`
const H5 = styled.h5`
  font-size: small;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  hr {
    width: 100%;
  }

  & h6,
  .highlight {
    color: ${({ strokeColor }) => strokeColor};
    font-weight: bold;
  }

  & blockquote {
    quotes: "“" "”";
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    font-style: italic;
    font-size: 20px;
    color: ${({ theme }) => theme.main.secondary};
    line-height: 30px;
    margin: 0;

    & p:before {
      content: open-quote;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      position: absolute;
      left: 0;
    }

    & p:after {
      content: close-quote;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      display: inline-block;
      position: relative;
      left: 5px;
    }
  }

  .inline-highlight {
    position: relative;
    z-index: 1;
    margin-right: 8px;

    ${p => p.theme.main.mixins.highlighted(p)}
  }

  .col-count-1 ul {
    column-count: 1;
  }

  .col-count-2 ul {
    column-count: 2;
  }

  .col-count-3 ul {
    column-count: 3;
  }

  @media only screen and (min-width: 992px) {
    .col-count-md-1 ul {
      column-count: 1;
    }

    .col-count-md-2 ul {
      column-count: 2;
    }

    .col-count-md-3 ul {
      column-count: 3;
    }
  }

  .ReactGridGallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

const PlayerWrapper = styled.div`
  & > * {
    min-height: 54px;
  }
`

export default ArtistPageTemplate

export const pageQuery = graphql`
  query ArtistPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        cover {
          publicURL
        }
        country
        color
        hideTitle
        hasHeader
        albums {
          title
          cover {
            childImageSharp {
              fluid(maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
            publicURL
          }
        }
        photos {
          title
          src {
            thumbnail: childImageSharp {
              fluid(maxHeight: 360) {
                src
                presentationWidth
                presentationHeight
              }
            }
            publicURL
          }
          alt
        }
        videos {
          title
          url
        }
        presse {
          title
          image {
            childImageSharp {
              fixed(width: 60) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          url
        }
        social
      }
    }
  }
`
